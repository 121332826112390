import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import EndPoint from "../../api/Endpoint";
import { useHttpClient } from "../../hooks/HttpHook";
import { slice } from 'lodash';
import UserNavbar from "../../components/NavBar/userNav.jsx";
import Loader from "../Loading/Loader";
import SignInAsAdmin from "../../components/SignInAsAdmin";
const UserSearchLinkList = (props) => {
    const location = useLocation();
    const address = location.state.address;
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [initialPosts, setInitialPosts]= useState([]);
    const [page, setPage] = useState(1);
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
    const [isLoader, setIsLoading] = useState(false);
    const loadMore = () => {
        const nextPage = page+1;
        setPage(nextPage);
        fetchData(nextPage);
    }

    useEffect(() => {
        // fetchData(page);
    }, []);

    useEffect(()=>{
        setPage(1);
        fetchData(1);
    },[address])

    const handleMouseDown = (e, item) =>{
        const url = item['link'];
        window.open(url, '_blank').focus();
    }
    const fetchData = async (pageArg) => {
        let body ={
            search_keyword:address,
            page: pageArg,
            limit: 10
        };
        setIsLoading(true);
        try {
            const responseData = await sendRequest(
                EndPoint.LOCATION_LISTING_FRONT_API,
                "POST",
                body
            );        
            setIsLoading(false);
            const lengthData = responseData.data.length;
            const totalCountVar = responseData.totalCount;
            if(lengthData >= totalCountVar){
                setShowLoadMoreButton(false);
            }else{
                setShowLoadMoreButton(true);
            }
            const initialDataArr = responseData.data;
            setInitialPosts(initialDataArr);            
        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    };

    return (
        <>
            <UserNavbar />
            <div className='main-cover'>
            <section className="page-inner-cover search-main-outer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-categories">Search Results</div>
                            <div className="pdf-inner-outer">
                                {
                                    initialPosts.length > 0 ?
                                    initialPosts?.map((item, index) => (
                                        <div key={item.address+index} onMouseDown={(e)=>handleMouseDown(e, item)} className="pdf-repeat-box d-flex justify-content-between">{item.link}
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8.083" height="14.148" viewBox="0 0 8.083 14.148">
                                                    <path id="chevron-down-solid" d="M38.285,167.712a1.012,1.012,0,0,0,1.43,0l6.061-6.061a1.011,1.011,0,1,0-1.43-1.43L39,165.569l-5.348-5.345a1.011,1.011,0,0,0-1.43,1.43l6.061,6.061Z" transform="translate(-159.925 46.073) rotate(-90)" />
                                                </svg>
                                            </span>
                                        </div>
                                    )):
                                    <div>
                                        No tracking links are available for the entered address.
                                    </div>
                                }
                            </div>
                            {
                                showLoadMoreButton &&
                                <div className="text-center load-btn">
                                    <button onClick={loadMore} type="button" className="btn ">
                                        Load More...
                                    </button>
                                </div>  
                            }
                        </div>
                    </div>
                </div>
                {isLoading?(
                <Loader />
                ):null}
            </section>
            <SignInAsAdmin></SignInAsAdmin></div>
        </>
    );
};
export default UserSearchLinkList;
