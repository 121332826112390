
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ButtonGroup, CloseButton, Col, Form, Image, Row, ToggleButton } from 'react-bootstrap'
import ImageTag from '../ImageTag';
import rightimages from '../../assets/img/right-side-images.png';
import cutright from '../../assets/img/cutright.png'
import cutup from '../../assets/img/cutup.png'
import UserNavbar from "../../components/NavBar/userNav.jsx";
import NavbarGuest from "../../components/NavBar/userNavGuest";
import useModal from './price-tag-modal';
import Loader from '../Loading/Loader';
import { checkBoundationArray } from './custom-helpers';
import PreviewModal from './preview-modal';
import PrintModal from './print-modal';
import missingImg from '../../assets/img/missing-img.png'
import NotFoundModal from './not-found-modal';
import SignInAsAdmin from "../../components/SignInAsAdmin";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome CSS
import { FaTimes } from 'react-icons/fa'; // Import the cross icon from react-icons
const PriceTag = () => {

  const isLoggedIn = localStorage.getItem('token');

  const { girdItems, listData, radioValue, viewByValue, radios, viewBy, girdItemsSelected, show, isLoader, printPdf, propsForNotFound, handleCloseNotFound, setIsLoading, setPrintPdf, handlePrint, handleMouseDown, setGirdItemsSelected, handleMouseDownCloseBtn, handleCheckChange, handleOnChangeSearch, setRadioValue, handleClose, handleShow, fetchInitialListData, fetchImagesFromAWS, handleRadioChange, handleGridChange, handleMobileSelection, setStartDate, startDate, handleStartDateChange, clearDate } =useModal(); 
  let clientXX = 0;
  let clientYY =0;

  useEffect(()=>{
    fetchInitialListData();
  },[])

  const handleDragStart = (event, ele, index) => {
    if(ele.image === ''){
      event.preventDefault();
    }
    clientXX = event.clientX;
    clientYY = event.clientY;
    // This method runs when the dragging starts
    console.log("Started");
  }

  const handleDrag = (event, ele, index) => {
    // This method runs when the component is being dragged
    // console.log("Dragging...")
    clientXX = event.clientX;
    clientYY = event.clientY;
  }

  const handleDragEnd = (event, ele, index) =>{
    const docDiv = document.getElementById(ele.id+ele.filename+index);
    const tempArr = checkBoundationArray(event, girdItemsSelected, docDiv, index, clientXX, clientYY);
    setGirdItemsSelected(tempArr);
    // This method runs when the dragging stops
    console.log("Ended");
  }

  return (
    <>
      {isLoggedIn ? <UserNavbar /> : <NavbarGuest />}
      <div className='main-cover'>
      <div className='container price-tag-container'>
      <div className="d-flex justify-content-between my-4 align flex-wrap">
        <div className="pricetag-title mb-2">Price Tags On-Demand</div>
        <div className="price-btn-cover mb-2">
        <button className='mr-2' onClick={handleShow}>
            <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="13" width="15" fill="#fff">
              <path d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg></span>
              PREVIEW
          </button>
          <button onClick={handlePrint}>
            <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="13" width="15" fill="#fff">
              <path d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg></span>
              PRINT
          </button>
        </div>
      </div>
      <div className="">
            <div className="border-0 bg-white border-top">
              <Row>
                <Col sm={4}>
                  <div className="theme-left-sidebar d-flex flex-column bg-light h-100" >
                    <div className="theme-sidebar-header p-3">
                      <div className='d-flex justify-content-between row'>
                      <Form.Group className="mb-2 pb-1 col-md-12 col-xl-6 leftSearch" /* className='mb-4 pb-1 col-md-12 col-xl-12 leftSearch form-group' */ controlId="search">
                        <Form.Control size="lg" type="text" placeholder="Search by name" onChange={(e)=>handleOnChangeSearch(e)} />
                      </Form.Group>

                      {/* Datepicker for Start Date */}
                        <div className="mb-2 pb-1 col-md-12 col-xl-6 pl-xl-0 rightDatePicker position-relative">
                          <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            className="form-control"
                            dateFormat="MM-dd-yyyy"
                            placeholderText="Start Date"
                          />{startDate && (
                              <button className="cross-calendar" onClick={clearDate}>
                                <FaTimes />
                              </button>
                            )}
                        </div>
                      </div>

                      <div className='gap-4 hstack flex-wrap'>
                      <Form.Check
                          type="checkbox"
                          label="Android"
                          id="checkboxandroid"
                          value="Android"
                          onChange={(e)=>handleMobileSelection(e)}
                          className='check-only-box'
                        />
                        <Form.Check
                          type="checkbox"
                          label="iPhone"
                          id="checkboxiPhone"
                          className="mr-auto check-only-box"
                          value="iOS"
                          onChange={(e)=>handleMobileSelection(e)}
                          
                        />
                        
                        <ButtonGroup className='btn-blue-outline d-sm-block d-none'>
                          {radios.map((radio) => (
                            <ToggleButton
                              key={radio.name}
                              id={`radio-${radio.name}`}
                              type="radio"
                              variant="outline-secondary"
                              className='custom-toggle'
                              name="radio"
                              value={radio.value}
                              checked={radioValue === radio.value}
                              onChange={(e)=> handleRadioChange(e.currentTarget.value)}
                            >
                              {radio.name}
                            </ToggleButton>
                          ))}
                        </ButtonGroup>
                        <span className="d-flex w-100 justify-content-between">
                          <div className="gap-2">
                            {viewBy.map((elem) => (
                              <ToggleButton
                                key={elem.name}
                                id={`elem-${elem.name}`}
                                type="radio"
                                variant="outline-secondary"
                                name="viewBy"
                                className='custom-toggle new-radio'
                                value={elem.value}
                                checked={viewByValue === elem.value}
                                onChange={(e) => handleGridChange(e.currentTarget.value)}
                              >
                                {elem.icon}
                              </ToggleButton>
                            ))}
                          </div>
                          <ButtonGroup className='btn-blue-outline d-block d-sm-none'>
                          {radios.map((radio) => (
                            <ToggleButton
                              key={radio.name}
                              id={`radio-${radio.name}`}
                              type="radio"
                              variant="outline-secondary"
                              className='custom-toggle'
                              name="radio"
                              value={radio.value}
                              checked={radioValue === radio.value}
                              onChange={(e)=> handleRadioChange(e.currentTarget.value)}
                            >
                              {radio.name}
                            </ToggleButton>
                          ))}
                        </ButtonGroup>
                        </span>
                      </div>
                    </div>
                    <div className="theme-sidebar-content overflow-auto px-3 pb-3">
                      {viewByValue === '1' ?
                        <ul className="list-unstyled m-0">
                        {listData.length > 0 ? listData.map((data) => {
                          const startDate = new Date(data.start_date+' ');
                          const currentDate = new Date();
                          const isEffective = startDate > currentDate;

                          return (
                            <li key={`default-${data.filename}`} className="mb-2 d-flex align-items-start justify-content-between">
                              <div className="d-flex align-items-start">
                              <Form.Check
                                type="checkbox"
                                id={`default-${data.filename}`}
                                label=""
                                value={data.filename}
                                onChange={(e) => handleCheckChange(e, 'list')}
                                className='pt-3'
                                checked={data.checked}
                              />
                              <Form.Label
                                onMouseDown={(e) => handleMouseDown(e, data.filename, 'list')}
                                className='theme-checkbox-label'
                              >
                                {data.name} 
                              </Form.Label>
                              </div>
                              {isEffective && (
                                <span className='mPriceCardLabel'>
                                  Effective {`${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}-${startDate.getFullYear()}`}
                                </span>
                              )}
                            </li>
                          );
                        }) : (
                          <div style={{ justifyContent: 'center' }} className="mb-2 d-flex align-items-center"> No Record Found</div>
                        )}
                      </ul>

                        // <ul className="list-unstyled m-0">
                        //   {listData.length > 0 ?listData.map((data) => (
                        //     <li key={`default-${data.filename}`} className="mb-2 d-flex align-items-center">
                        //       <Form.Check
                        //         type="checkbox"
                        //         id={`default-${data.filename}`}
                        //         label=""
                        //         value={data.filename}
                        //         onChange={(e)=>handleCheckChange(e, 'list')}
                        //         className='pt-3'
                        //         checked={data.checked}
                        //       />
                        //       <Form.Label
                        //         onMouseDown={(e)=>handleMouseDown(e, data.filename, 'list')} 
                        //         className='theme-checkbox-label'
                        //       >
                        //         {data.name}
                        //       </Form.Label>
                        //     </li>
                        //   )): <div style={{justifyContent:'center'}} className="mb-2 d-flex align-items-center"> No Record Found</div>}
                        // </ul>

                        :
                        <Row className="pt-2 g-3">
                          {girdItems.length>0 ? girdItems.map((data, index) => {
                            return (
                              <Col className="mb-4" sm={6} key={data.filename+index}>
                                <div className='ratio ratio-1x1'>
                                  <div className="bg-black-new">
                                    <div className="position-relative w-100 h-100">
                                      {
                                        data.image!=="Not found" &&
                                        <Form.Check
                                          type="checkbox"
                                          id={`check-api-${data.filename}`}
                                          className=" m-0 position-absolute rounded-0 check-image-cover pt-4"
                                          value={data.filename}
                                          checked={data.checked}
                                          onChange={(e)=>handleCheckChange(e, 'grid')}
                                          label=""
                                        />
                                      }
                                      <Form.Label onMouseDown={(e)=>handleMouseDown(e, data.filename, 'grid', data.image)} className={`stretched-link w-100 h-100 border border-1 border-secondary last-ride`}>
                                        <Image src={data.image!=="Not found"?data.image:missingImg} className="w-100 h-100 object-fit-cover" alt={`Image ${data.filename} Data `} />
                                      </Form.Label>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            )
                          }): <div style={{justifyContent:'center'}} className="mb-2 d-flex w-100 align-items-center"> No Record Found</div>}
                        </Row>
                      }
                    </div>
                  </div>
                </Col>
                <Col sm>
                  <div className="theme-right-sidebar h-100 mt-4">
                    <Row className='p-tag-box-cover'>
                      {girdItemsSelected.map((elemData, index) => {
                        return (
                          <Col draggable 
                          onDragStart={(e)=>handleDragStart(e, elemData, index)}
                          onDrag={(e)=>handleDrag(e, elemData, index)}
                          onDragEnd={(e)=>handleDragEnd(e, elemData, index)}
                          className="mb-4" 
                          md={4} 
                          key={elemData.id+index}
                          data-elem-filename={elemData.filename !='' ? elemData.filename:'Not found'}
                          data-elem-image={elemData.image !='' ? elemData.image:'Not found'}
                          data-elem-index={index}
                          id={elemData.id+elemData.filename+index}
                          >
                            <div className='ratio ratio-1x1'>
                              {/* <Placeholder as="div" animation="glow">
                                <Placeholder bg="secondary" className="h-100 w-100" />
                              </Placeholder> */}
                              <div className="bg-black-new">
                                <div className="position-relative w-100 h-100">
                                  {
                                    elemData.image !== '' &&
                                    <>
                                      <button onMouseDown={()=>handleMouseDownCloseBtn(elemData, index)} aria-label="Remove Item" className="custom-new-button shadow position-absolute rounded-circle text-white new-red-bg d-flex align-items-center justify-content-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8.038" height="8.038" viewBox="0 0 8.038 8.038">
                                          <path  d="M9.508,9.508a1.057,1.057,0,0,1,1.514,0l2.15,2.154,2.191-2.154a1.057,1.057,0,0,1,1.514,0,.994.994,0,0,1,0,1.514l-2.113,2.15,2.113,2.191a1.071,1.071,0,0,1-1.514,1.514l-2.191-2.113-2.15,2.113a.994.994,0,0,1-1.514,0,1.057,1.057,0,0,1,0-1.514l2.154-2.191-2.154-2.15a1.057,1.057,0,0,1,0-1.514Z" transform="translate(-9.189 -9.189)" fill="CurrentColor"/>
                                        </svg>
                                      </button>
                                      <Image src={elemData.image} className="w-100 h-100 object-fit-cover" alt={`Image ${elemData.id} Data `} />
                                    </>
                                  }
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      })}

                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <NotFoundModal showModal={propsForNotFound.show} message={propsForNotFound.message} messageError={propsForNotFound.messageError} handleClose={handleCloseNotFound}/>
          <PreviewModal show={show} handleClose={handleClose} girdItemsSelected={girdItemsSelected}/>
          <PrintModal setIsLoading={setIsLoading} show={printPdf} setPrintPdf = {setPrintPdf} handleClose={handleClose} girdItemsSelected={girdItemsSelected} />
          {isLoader?(
          <Loader />
        ):null}
      </div>
      <SignInAsAdmin></SignInAsAdmin></div>
    </>
    
  );
};
export default PriceTag;