import React from "react";
import { Link } from 'react-router-dom';
import ImageTag from "../../components/ImageTag";
import PriceOneIcon from "../../assets/img/House-Merch-logo.png"
import PricetwoIcon from "../../assets/img/Boost logo blue.png"
import BoostGearIcon from "../../assets/img/boost_gear_ramp.png";
import BoostGearRightIcon from "../../assets/img/drop_color.svg";
import TshirtBoostIcon from "../../assets/img/Shirt.png";
import DigitalFlyerIcon from "../../assets/img/boost-flyers.svg"

import Constants from "../../api/Constants";
import CheckPermissionToPerformAction from "../../components/PrivateRoute/PrivateRouteHook"

import { useState, useEffect } from 'react';

import SignInAsAdmin from "../../components/SignInAsAdmin";

const UserMainIndex = () => {
    const { modulePriceCards, moduleDigitalFlyers } = CheckPermissionToPerformAction();
    
    // console.log('modulePriceCards', modulePriceCards);
    // console.log('moduleDigitalFlyers', moduleDigitalFlyers);
    
    const handleMouseDown = (e) =>{
        const url = "http://boostmobilegear.com/";
        window.open(url, '_blank').focus();
    }

    // check user is admin or not for below link condition
    const user = JSON.parse(localStorage.getItem('user'));

    const [token, setToken] = useState('');

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
        setToken(storedToken);
        }
    }, []);

    return (
        <>
            <section className="price-of-new-inner new-panel-work position-relative">
                <div className="price-main-box">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="price-main-outer d-flex flex-wrap justify-content-center">
                                <div className="price-boxs">
                                    <Link className="price-main-outer-link-box" to={`${Constants.FRONT_BASE_PATH}/user/categories`}>
                                        <div className="price-select-one">
                                            <div className="min-h-195">
                                                {/* <ImageTag src={PriceOneIcon} /> */}
                                                <svg width="180" height="128" viewBox="0 0 180 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M83.3479 15.7267L90.3961 12.5812L97.4443 15.7267L111.541 22.0166L125.639 28.3076L139.736 34.5978V94.3027H125.639V83.4074V40.8884L111.541 34.5978V47.179V72.5117V83.4074V94.3027H97.4443V83.4074V72.5117V61.6161V53.4696V40.8884V28.3076L90.3961 25.1621L83.3479 28.3076V40.8884V53.4696V55.1315V61.6161V66.0268V72.5117V83.4074V94.3027H69.2507V83.4074V72.5117V66.0268H55.1535V83.4074V94.3027H41.0563V66.0268V55.1315V34.5978L55.1535 28.3076V40.888V55.1315H69.2507V47.179V34.5978V22.0166L83.3479 15.7267ZM26.96 105.198H153.832V28.3076L90.3969 0L26.96 28.3068V105.198Z" fill="white" />
                                                    <path d="M0 127.785V116.351H4.27401V120.393H11.272V116.351H15.5309V127.785H11.272V123.436H4.27401V127.785H0Z" fill="white" />
                                                    <path d="M24.682 116.135H25.3592C32.5829 116.135 33.5159 119.64 33.5159 121.806V122.268C33.5159 124.404 32.5979 128 25.3592 128H24.682C17.4282 128 16.5102 124.404 16.5102 122.268V121.806C16.5102 119.64 17.4282 116.135 24.682 116.135ZM29.0914 121.914C29.0914 120.7 28.339 119.209 25.0131 119.209C21.642 119.209 20.9347 120.7 20.9347 121.914V122.098C20.9347 123.297 21.7022 124.911 25.0131 124.911C28.3239 124.911 29.0914 123.343 29.0914 122.129V121.914Z" fill="white" />
                                                    <path d="M34.3726 121.806V116.351H38.6616V121.468C38.6616 122.867 39.2636 124.542 42.0929 124.542C44.877 124.542 45.5091 122.867 45.5091 121.468V116.351H49.7831V121.806C49.7831 124.02 48.7597 127.969 42.4089 127.969H41.8069C35.2906 127.969 34.3726 124.02 34.3726 121.806Z" fill="white" />
                                                    <path d="M57.3327 128C51.1474 128 50.5003 125.433 50.5003 123.943V123.789H55.1957C55.2408 124.404 55.5569 125.403 57.9949 125.403H58.1303C60.5231 125.403 60.8693 124.88 60.8693 124.327V124.312C60.8693 123.743 60.5984 123.343 58.2356 123.282L56.1588 123.22C51.8246 123.082 50.6658 121.561 50.6658 119.778V119.64C50.6658 117.78 51.8999 116.151 57.1069 116.151H58.4463C63.9845 116.151 65.2486 117.949 65.2486 119.762V119.885H60.5984C60.5532 119.47 60.3125 118.671 57.8895 118.671H57.739C55.4967 118.671 55.2709 119.071 55.2709 119.517V119.532C55.2709 119.993 55.602 120.377 57.7089 120.408L59.7255 120.454C63.9845 120.562 65.6399 121.607 65.6399 123.897V124.112C65.6399 126.048 64.5865 128 58.7022 128H57.3327Z" fill="white" />
                                                    <path d="M66.5169 116.351H78.9025V119.148H70.7307V120.577H78.737V123.359H70.7307V124.926H79.0229V127.785H66.5169V116.351Z" fill="white" />
                                                    <path d="M98.2167 127.785V116.351H104.086L107.517 122.775L111.009 116.351H116.637V127.785H112.408V120.654L108.48 127.785H106.298L102.385 120.654V127.785H98.2167Z" fill="white" />
                                                    <path d="M117.998 116.351H130.384V119.148H122.212V120.577H130.218V123.359H122.212V124.926H130.504V127.785H117.998V116.351Z" fill="white" />
                                                    <path d="M135.926 119.132V121.1H140.832C141.765 121.1 142.126 120.669 142.126 120.116V120.101C142.126 119.563 141.78 119.132 140.832 119.132H135.926ZM135.926 123.866V127.785H131.652V116.351H141.825C145.106 116.351 146.535 117.426 146.535 119.286V119.455C146.535 121.115 145.121 121.791 144.278 122.006C145.647 122.329 146.58 123.251 146.58 124.726V126.417C146.58 127.216 146.686 127.508 146.791 127.708V127.785H142.427C142.337 127.662 142.291 127.508 142.291 127.185V125.848C142.291 124.527 141.704 123.866 140.019 123.866H135.926Z" fill="white" />
                                                    <path d="M155.739 128H155.152C148.259 128 147.236 124.419 147.236 122.252V121.806C147.236 119.624 148.169 116.135 155.152 116.135H155.739C162.556 116.135 163.519 119.301 163.534 120.761V120.961H159.095C159.019 120.669 158.643 119.209 155.408 119.209C152.323 119.209 151.66 120.608 151.66 121.883V122.068C151.66 123.297 152.413 124.911 155.423 124.911C158.718 124.911 159.05 123.297 159.11 123.036H163.534V123.282C163.534 124.788 162.496 128 155.739 128Z" fill="white" />
                                                    <path d="M164.469 127.785V116.351H168.743V120.393H175.741V116.351H180V127.785H175.741V123.436H168.743V127.785H164.469Z" fill="white" />
                                                    <path d="M85.3654 119.227H85.7218C89.5238 119.227 90.0148 121.071 90.0148 122.212V122.454C90.0148 123.579 89.5317 125.471 85.7218 125.471H85.3654C81.5476 125.471 81.0644 123.579 81.0644 122.454V122.212C81.0644 121.071 81.5476 119.227 85.3654 119.227ZM87.6862 122.268C87.6862 121.629 87.2901 120.845 85.5396 120.845C83.7654 120.845 83.3931 121.629 83.3931 122.268V122.365C83.3931 122.996 83.7971 123.846 85.5396 123.846C87.2822 123.846 87.6862 123.021 87.6862 122.382V122.268Z" fill="white" />
                                                    <path d="M90.5291 119.34H96.9211V120.812H92.7548V121.565H96.8339V123.045H92.7548V125.358H90.5291V119.34Z" fill="white" />
                                                </svg>
                                            </div>
                                            <div className="price-title">Merch Grids</div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="price-boxs">
                                    <div onClick={handleMouseDown} className="price-select-two">
                                        <div className="price-select-two-img">
                                            <ImageTag src={TshirtBoostIcon} />
                                        </div>
                                        <div className="price-title">BOOST MOBILE GEAR</div>
                                        {/*<div className="boost-gear-img">
                                            <ImageTag src={BoostGearIcon} />
                                        </div>
                                        <div className="boost-gear-right-icon"><ImageTag src={BoostGearRightIcon} /></div>*/}
                                    </div>
                                </div>
                                

                                {/* M Price Tag Work */}
                                {modulePriceCards && 
                                <div className="price-boxs">
                                <Link className="price-main-outer-link-box" to={`${Constants.FRONT_BASE_PATH}/user/price-tag`}>
                                    <div className="price-select-three">
                                        <div className="price-icon-cover">
                                            <ImageTag src={PricetwoIcon} />
                                            
                                        </div>
                                        <div className="price-title">Price Tags</div>
                                    </div>
                                </Link>
                                </div> }
                                
                                {/* M Price Tag Work */}
                                
                                { moduleDigitalFlyers && 
                                <div className="price-boxs">    
                                    <Link className="price-main-outer-link-box digital-box-link" to={`${Constants.FRONT_BASE_PATH}/user/digital-flyers`}>
                                        <div className="price-select-one digital-box">
                                            <div>
                                                <ImageTag src={DigitalFlyerIcon}  className="digital-icon-img"/>
                                            </div>
                                            <div className="price-title">Digital Flyers</div>
                                        </div>
                                    </Link>
                                </div> }

                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            {/* <div className="d-flex justify-content-end mt-5 sign-in-link"> 
                <a className="me-10" href="javascript:void(0)">Need Assistance?</a>
                {user && user.role === 'ADMIN' && (
                    <a href="javascript:void(0)"  onClick={handleGoToAdminLink}>Administrator</a>
                    // {`${Constants.REACT_APP_FRONT_APPLICATION_BASE_URL}`}
                )}
            </div>  */}
            <SignInAsAdmin></SignInAsAdmin>
        </>

    );
};
export default UserMainIndex;