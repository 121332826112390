import { useEffect, useState } from "react";
import Navbar from "../../components/NavBar/userNav";
import NavbarGuest from "../../components/NavBar/userNavGuest";
import Loader from "../../components/Loading/Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignInAsAdmin from "../SignInAsAdmin";
import { useHttpClient } from "../../hooks/HttpHook";
import EndPoint from "../../api/Endpoint";
import Constants from "../../api/Constants";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const DigitalFlyersIndex = () => {

    const [isLoading, setIsLoading] = useState(false);
    const { sendRequest } = useHttpClient();
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
    const [modulesList, setModulesList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [initLoadMore, setInitLoadMore] = useState(true);
    const isLoggedIn = localStorage.getItem('token');

    const fetchData = async (pageArg) => {
        let deviceId = JSON.parse(localStorage.getItem('user'))?.userId;
        if(deviceId == '' || deviceId == undefined) {
            deviceId = JSON.parse(localStorage.getItem('user'))?._id;
        }

        let body ={
            page: pageArg
        };

        // api Call
        setIsLoading(true);
        try {
            const responseData = await sendRequest(
                EndPoint.DIGITAL_FLYER_LIST,
                "POST",
                body
            );

            setIsLoading(false);

            const lengthData = pageArg * Constants.PAGINATION;
            const totalCountVar = responseData.totalNumOfFlyers;

            if(lengthData >= totalCountVar) {
                setShowLoadMoreButton(false);
            } else {
                setShowLoadMoreButton(true);
            }
            setInitLoadMore(false);
            const initialDataArr = responseData.allFlyersCategory; 
            setModulesList((prevList) => [...prevList, ...initialDataArr]);
        
        } catch (err) {
            setIsLoading(false);
        }
    };

    const handleLoadMore = () =>{
        const pageCount = pageNumber;
        setPageNumber(pageCount+1);
        fetchData(pageCount+1);
    }

    useEffect(() => {
        fetchData(1);
    }, []);

    return (
        <>
        {isLoggedIn ? <Navbar /> : <NavbarGuest />}
        <ToastContainer/>
        <div className="main-cover">
            <div className="container">
                <div className="row mt-4 mb-4">
                    <div className="col-md-6 category-title">DIGITAL FLYERS</div>
                    <div className="col-md-6 text-right">
                    </div>
                </div>

                <table id="tezst" className="table table-striped digital-flyer-table">
                    <thead>
                        <tr>
                            <th>Categories</th>
                            {/* <th className="text-center">File Counts</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            modulesList.length > 0 ?
                                modulesList.map((element, index) => {
                                    return (
                                        
                                        <tr key={element._id}>
                                            <td>
                                                {/* {element.fileCount > 0 ? (
                                                    <Link className="fliyer-list-title" to={`${Constants.FRONT_BASE_PATH}/digital-flyers/${element._id}`}>
                                                        {element.categoryName}
                                                    </Link>
                                                ) : (
                                                    <span className="fliyer-list-title" title="No flyers is there in this category">
                                                        {element.categoryName}
                                                    </span>
                                                )} */}
                                                <Link className="fliyer-list-title" to={`${Constants.FRONT_BASE_PATH}/digital-flyers/${element._id}`}>
                                                    {element.categoryName}
                                                </Link>
                                            </td>
                                            {/* <td width="200"  className="text-center">
                                                <span className="mFileCount">
                                                    {element.fileCount}
                                                </span>
                                            </td> */}
                                        </tr>
                                    );
                                })
                            : <tr><td colSpan="2">{isLoading ? "Loading records..." : "No flyer category found."}</td></tr>
                        }
                    </tbody>
                </table>

                {
                    showLoadMoreButton && !initLoadMore &&
                    <div className="text-center load-btn">
                        <button onClick={handleLoadMore} type="button" className="btn ">
                            Load More...
                        </button>
                    </div>
                }

                {isLoading ? (<Loader />) : null}

                {initLoadMore && <div className="text-center">
                    <Spinner animation="border" />;
                </div>}

            </div>

            <SignInAsAdmin></SignInAsAdmin>
        </div>
        </>
    );
};

export default DigitalFlyersIndex;
