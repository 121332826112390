import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import EndPoint from "../../api/Endpoint";
// import headerLogoNew from "../../assets/img/header-logo-new.png";
import headerLogoNew from "../../assets/img/Header-logo.png";
import ImageTag from "../ImageTag";
import { useHttpClient } from "../../../src/hooks/HttpHook";
import AddressWithSearch from "./search-bar-parent";
import jwt_decode from "jwt-decode";
import Constants from "../../api/Constants";

import bellIconNotificationFalse from "../../assets/img/bell_0.svg";
import bellIconNotificationTrue from "../../assets/img/bell_1.svg";
import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertViewModal from "../Alerts/alert-view-modal";
import Loader from "../../components/Loading/Loader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { settingActions } from "../../store/setting";
import AlertPreviewModal from "../Alerts/alert-preview-modal";

const UserNavbar = () => {
    const navigate = useNavigate();
    const { sendRequest } = useHttpClient();

    //Alert
    const [isLoading, setIsLoading] = useState(false);
    const [viewData, setViewData] = useState([]);
    const [unreadAlertsCount, setUnreadAlertsCount] = useState([]);
    const [showViewAlert, setShowViewAlert] = useState(false);
    const dispatch=useDispatch()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [previewFileName, setPreviewFileName] = useState('');
    const [previewFileType, setPreviewFileType] = useState('');

    const isUnreadNotification = useSelector((state) => state.setting.isUnreadNotification);

    // show popup first time 
    const mFirstPopup = localStorage.getItem('mfirstpopup');
    if(isUnreadNotification && (mFirstPopup == 'show' || mFirstPopup == null)) {
        const bellIcon = document.querySelector(".mBellIcon");
        if (bellIcon) {
            bellIcon.click();
        }
        localStorage.setItem('mfirstpopup', 'hide');
    }

    //Alert

    const logoutAPI = EndPoint.LOGOUT_API_URL;

    const handleLogout = async () => {
        try {
            const response = await sendRequest(
                logoutAPI,
                "GET"
            );

            if (response) {
                logoutFb();
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };
    
    const logoutFb = async() => {
        const tokenData = JSON.parse(JSON.stringify(localStorage.getItem('token')));
        // Decode the JWT token
        const jwtData = await jwt_decode(tokenData);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('fpnt');
        localStorage.removeItem('mfirstpopup');
        if(jwtData){
            if(jwtData.role === "ADMIN"){
                window.location.href = `${Constants.FRONT_BASE_PATH}/login`;
            }else if(jwtData.role === "FRONT"){
                window.location.href = `${Constants.FRONT_BASE_PATH}/user/login`;
            }
        }else{
            window.location.href = `${Constants.FRONT_BASE_PATH}/user/login`;
        }
        
    }
    const handleMouseDown = (event) => {
        event.preventDefault();
        navigate(`${Constants.FRONT_BASE_PATH}/`);
    }

    // alert popup code
    const handleAlertNotificationPopup = async() => {
        setIsLoading(true);
        let mUserId = JSON.parse(localStorage.getItem('user')).userId;
        if(mUserId == '' || mUserId == undefined) {
            mUserId = JSON.parse(localStorage.getItem('user'))._id;
        }
        
        try {
            const responseData = await sendRequest(
                EndPoint.GET_UNREAD_ALERT_LIST+`${mUserId}`,
              "GET"
            );
    
            if(responseData.allUnreadAlerts){
                setViewData(responseData.allUnreadAlerts);
                setUnreadAlertsCount(responseData.unreadAlertsCount);
            } else {
                setViewData([]);
                setUnreadAlertsCount(0);
            }
            
            setShowViewAlert(true);
        } catch (err) {
            closeViewAlertModal();
            console.log(err);
        }
        
        setIsLoading(false);
    };

    const closeViewAlertModal = () => {
        setShowViewAlert(false);
    };

    const handleFileDownload = async(event, id, attachment) => {
        
        try {
            setIsLoading(true);
            const responseData = await sendRequest(
              EndPoint.GET_ALERT_FILE+`${attachment}`,
              "GET",
            );

            setIsLoading(false);
            // closeDeleteAlertModal();
            
            if (!responseData.error) {
                /* console.log(responseData.data);
                const url = responseData.data;
                const fileName = responseData.name;
            
                // Create a temporary link element
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName; // Set the file name for the download
            
                // Append the link element to the document body
                document.body.appendChild(a);
                a.target = '_blank';
                // Trigger a click event on the link to initiate the download
                a.click();
            
                // Remove the link element from the document body
                document.body.removeChild(a);
            
                // Revoke the Blob URL to free up memory
                URL.revokeObjectURL(url); */

                // Extract data from response
                const url = responseData.data;
                const fileName = responseData.name;

                // Determine file type based on file name
                const fileType = getFileType(fileName);

                // Set preview data to show in modal
                setPreviewUrl(url);
                setPreviewFileName(fileName);
                setPreviewFileType(fileType);

                // Open the modal to display the file preview
                setIsModalOpen(true);
            }

        } catch (err) {
            setIsLoading(false);
            toast.error('File not found on bucket', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            // closeDeleteAlertModal();
            console.log(err);
        }
    }

    const getFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        if (extension === 'pdf') {
          return 'pdf';
        } else {
          return 'image';
        }
    };
    
    const handleCloseModal = () => {
        // Close the modal and reset preview data
        setIsModalOpen(false);
        setPreviewUrl('');
        setPreviewFileName('');
        setPreviewFileType('');
    };

    const fetchNotificationStatus = async () => {
        try {
            let deviceId = JSON.parse(localStorage.getItem('user')).userId;
            if(deviceId == '' || deviceId == undefined) {
                deviceId = JSON.parse(localStorage.getItem('user'))._id;
            }
            const responseData = await sendRequest(
                EndPoint.GET_IF_ANY_UNREAD_ALERT+`${deviceId}`,
                "GET",
            );
            dispatch(settingActions.setUnreadNotificationFlag(responseData.isAnyNotificationUnread));

            // save token for push notifiacation
            let mBody ={
                deviceId: deviceId,
                deviceToken: localStorage.getItem('fpnt'),
                deviceType: 'WEB',
            };
            await sendRequest(EndPoint.SAVE_DEVICE_TOKEN, "POST", mBody);
            // save token for push notifiacation
            

        } catch (error) {
            console.error('Error fetching notification status:', error);
        }
    };
    // alert popup code

    useEffect(() => {
        // Fetch notification status when component mounts
        fetchNotificationStatus();
    }, []);

    return (
 
        <header className="header-outer user-front-nav-bar" style={{background: '#fc5400'}}>
            <div className="container">
                <div className="row">
                    <div className="align-items-center all-header-in d-flex flex-wrap gap-4 justify-content-between w-100">
                        <div className="head-logo-new mr-auto" onMouseDown={handleMouseDown}>
                            <ImageTag src={headerLogoNew} />
                        </div>
                        <div className="drop-search-outer d-flex align-items-center  order-lg-last">
                            <div className="dropdown-cover">
                                <Dropdown alignRight>
                                    <Dropdown.Toggle id="dropdown-basic">
                                    <span className="d-sm-none d-inline-flex align-text-top">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 512 512">
                                                <path fill="currentColor" d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"/></svg>
                                        </span>
                                        {/* <span className="text-truncate mw-150 d-sm-inline-block d-none align-middle">
                                            {JSON.parse(localStorage.getItem('user')).name ? JSON.parse(localStorage.getItem('user')).name : 'Admin'}
                                        </span>  */}  
                                        <span className="text-truncate mw-150 d-sm-inline-block d-none align-middle">
                                            {(() => {
                                                const user = localStorage.getItem('user');
                                                if (user) {
                                                    try {
                                                        const parsedUser = JSON.parse(user);
                                                        return parsedUser.name ? parsedUser.name : 'Admin';
                                                    } catch (error) {
                                                        console.error('Error parsing user data from localStorage', error);
                                                        window.location.href = `${Constants.FRONT_BASE_PATH}/user/login`;
                                                    }
                                                } else {
                                                    window.location.href = `${Constants.FRONT_BASE_PATH}/user/login`;
                                                }
                                            })()}
                                        </span>

                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item >
                                            {(localStorage.getItem('token') == null) ? (
                                                <Link className="nav-link" to="/login">
                                                    Login
                                                </Link>
                                            ) : (
                                                <div>
                                                    <Link className="nav-link" to={`${Constants.FRONT_BASE_PATH}/user/change-password`}>
                                                        Change Password
                                                    </Link>
                                                    <div className="nav-link" onClick={handleLogout} /* onClick={logoutFb} */>
                                                        Logout
                                                    </div>
                                                </div>

                                            )}
                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            
                            <div className="ml-3">
                                <ImageTag className="mBellIcon" src={isUnreadNotification ? bellIconNotificationTrue : bellIconNotificationFalse} onClick={handleAlertNotificationPopup}/>
                            </div>

                        </div>
                        <div className="search-inner d-flex">
                            <AddressWithSearch/>
                        </div>
                    </div>
                </div>

                <AlertViewModal 
                    showModal={showViewAlert}
                    viewData={viewData}
                    unreadAlertsCount={unreadAlertsCount}
                    handleClose={closeViewAlertModal}
                    handleFileDownload={handleFileDownload}
                />

                <AlertPreviewModal
                    show={isModalOpen}
                    handleClose={handleCloseModal}
                    url={previewUrl}
                    fileName={previewFileName}
                    fileType={previewFileType}
                />

                {isLoading ? (<Loader />) : null}

            </div>

        </header>
        //     </div>
        // </nav>
    );
};
export default UserNavbar;