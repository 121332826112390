import React, { useEffect } from 'react';
import axios from 'axios';
import Constants from "../../api/Constants";
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; 
import Loader from '../Loading/Loader';

import { authActions } from "../../store/auth";
import { useSelector, useDispatch } from "react-redux";

const UserAuthenticate = () => {

  const dispatch = useDispatch();

  const { token } = useParams();
  const navigate = useNavigate();
  let show = false;

  useEffect(() => {
    if (!show) {
      show=true;
      authenticateAdmin();
    }
  });

  const authenticateAdmin = async () => {
    try {
      const response = await axios.post(`${Constants.BASE_URL}user-front/authenticate`, { token });
      
      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        dispatch(authActions.login({data : response}));
        // window.location.href= `${Constants.FRONT_BASE_URL}`;
        navigate('/');
        console.log('User authenticated as admin.');
      } else {
        console.error('Authentication failed:', response.data.error);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('mfirstpopup');
        // toast.error('An error occurred while authenticating. Please try again later.');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error authenticating:', error);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('mfirstpopup');
      toast.error('An error occurred while authenticating. Please try again later.');
      navigate('/login');
    }
  };

  return (
    <Loader />
  );
};

export default UserAuthenticate;
