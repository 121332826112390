import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
// import headerLogoNew from "../../assets/img/logo-inner.svg";
import headerLogoNew from "../../assets/img/Header-logo.png";
import ImageTag from "../ImageTag";
import Constants from "../../api/Constants";

const UserNavGuest = () => {
    const navigate = useNavigate();

    const handleLogoClick = (event) => {
        event.preventDefault();
        if (window.location.pathname.includes('digital-flyers')) {
            navigate(`${Constants.FRONT_BASE_PATH}/user/digital-flyers`);
        }
        if (window.location.pathname.includes('price-tag')) {
            navigate(`${Constants.FRONT_BASE_PATH}/user/price-tag`);
        }
    }

    return (
        <header className="header-outer user-front-nav-bar" style={{background: '#fc5400'}}>
            <div className="container">
                <div className="row">
                    <div className="align-items-center all-header-in d-flex flex-wrap gap-4 justify-content-between w-100">
                        <div className="head-logo-new mr-auto" onClick={handleLogoClick}>
                            <ImageTag src={headerLogoNew} />
                        </div>
                        <div className="drop-search-outer d-flex align-items-center  order-lg-last">
                            <div className="dropdown-cover">
                                <Dropdown alignRight>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <span className="d-sm-none d-inline-flex align-text-top">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 512 512">
                                                <path fill="currentColor" d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"/></svg>
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item >
                                            <Link className="nav-link" to="/login">
                                                Login
                                            </Link>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
export default UserNavGuest;