import React from 'react'
// import HeadLogo from '../../assets/img/boost-logo.png'
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import EndPoint from "../../api/Endpoint";
import Constants from "../../api/Constants";
import { useParams } from 'react-router-dom';
import Loader from "../Loading/Loader";
import {Link} from 'react-router-dom';

import HeadLogo from "../../assets/img/hom-login-large.png";
const checkValidity = (password, confirmPassword) => {
  return !!password && !!confirmPassword;
};
const UserResetPassword = () => {

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      window.location.href = `${Constants.FRONT_BASE_URL}`;
    }
  }, []);
  const [show, setShow] = useState(false);
  const [formerrors, setFormErrors] = useState({});
  const [user, setUser] = useState({ password: "", confirmPassword: "" });
  let { token } = useParams();
  const [responseObj, setResponse] = useState({
    show: false,
    message: "",
    type: "error",
  });
  const isValid = checkValidity(user.password, user.confirmPassword);
  const [isLoading, setIsLoading] = useState(false);
  const onChangeHandler = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };


  const validateInput = (event) => {
    let { name, value } = event.target;
    setFormErrors(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {

        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (user.confirmPassword && value !== user.confirmPassword) {
            stateObj["confirmPassword"] = "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = user.confirmPassword ? "" : formerrors.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (user.password && value !== user.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  }



  const onSubmit = (event) => {
    if (event) event.preventDefault();
    if (!user.password.match(Constants.PASSWORD_VALIDATE) || !user.confirmPassword.match(Constants.PASSWORD_VALIDATE)) {
      setResponse({
        show: true,
        message: "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
        type: "warning",
      });
      return;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+token
    }
    const resetPass = {
      password: user.password,
      token: token
    };
    setIsLoading(true);
    axios
      .post(EndPoint.RESET_PASSWORD_URL_FRONT, resetPass, {
        headers:headers
      })
      .then(
        (response) => {
          setIsLoading(false);
          setResponse({
            show: true,
            message: "Password has been reset successfully. Please click on signin below.",
            type: "success",
          });
          setUser({ confirmPassword: "", password: "" });
          // window.location.href = `${EndPoint.FRONT_USER_LOGIN_URL}`;
        },
        (error) => {
          let msgResponse = error.response.data.message === "jwt expired" ? "Token expired please send the request again to reset the password." :error.response.data.message;
          setIsLoading(false);
          setResponse({
            show: true,
            message: msgResponse,
            type: "error",
          });
        }
      )
      .catch((e) => {setIsLoading(false);console.log(e)});

  };
  return (
    <section className='login-section-height'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='head-logo head-logo-for-user'>
              <img src={HeadLogo}></img>
            </div>
            <div className='white-outer-box new-login-signup'>
              <div className='signin-title'>RESET PASSWORD</div>
              {responseObj.show ? (
                <div
                  className={
                    responseObj.type == "success"
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {responseObj.message}
                </div>
              ) : (
                ""
              )}
              <div className="input-sign-in-inner">
                <Form novalidate>

                  <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      name="password"
                      value={user.password}
                      onChange={onChangeHandler}
                      placeholder="New Password"
                      onBlur={validateInput}
                    />
                    {formerrors.password && (
                      <p className="text-warning">{formerrors.password}</p>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      value={user.confirmPassword}
                      onChange={onChangeHandler}
                      placeholder="Confirm New Password"
                      onBlur={validateInput}
                    />
                    {formerrors.confirmPassword && (
                      <p className="text-warning">{formerrors.confirmPassword}</p>
                    )}
                  </Form.Group>
                  <Button className='btn-primary w-100 mb-3' variant="primary" onClick={onSubmit} type="button"
                    // disabled={!isValid || (user.password !== user.confirmPassword)}
                  >
                    SUBMIT
                  </Button>
                </Form>
                {responseObj.show ? (
                  <div className="reset-text auto-sign-up w-100">
                    <Link to={`${Constants.FRONT_BASE_PATH}/user/login`}>Sign In</Link>
                  </div>)
                  :null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading?(
        <Loader />
      ):null}
    </section>
  )
}
export default UserResetPassword;