import axios from "axios";
import { useEffect, useState } from "react";
import EndPoint from "../../api/Endpoint";
import { Button, Form } from "react-bootstrap";
import logo from "../../assets/img/hom-login-large.png";
import Constants from "../../api/Constants";
import ImageTag from "../ImageTag";
// import HeadLogo from "../../assets/img/boost-logo.png";
import { Link } from "react-router-dom";
import Loader from "../Loading/Loader";
import HeadLogo from "../../assets/img/hom-login-large.png";
const DeActivateUser = () => {
  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      window.location.href= `${Constants.FRONT_BASE_URL}`;
    }
  }, []);

  const [show, setShow] = useState(false);
  const [formerrors, setFormErrors] = useState({});
  const [user, setUser] = useState({ email: "", password: "" });
  const [responseObj, setResponse] = useState({
    show: false,
    message: "",
    type: "error",
  });
  const [isLoading, setIsLoading] = useState(false);
  const onChangeHandler = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const validate = () => {
    let errors = {};
    //password field
    if (!user.password) {
      errors.password = "Password is required";
    }

    //email field
    if (!user.email) {
      errors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(user.email)) {
      errors.email = "Email address is invalid";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  //Ch019@test.com
  const onSubmit = () => {
    if (!validate(user)) {
        console.log("Validateeeeeed")
        return;
    }
    setIsLoading(true);
    axios
      .post(EndPoint.DEACTIVATE_EMAIL_API, user)
      .then(
        (response) => {
          setIsLoading(false);
          setResponse({
            show: true,
            message: response.data.message,
            type: "success",
          });
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.data));
          setUser({ email: "", password: "" });
        },
        (error) => {
          setIsLoading(false);
          setResponse({
            show: true,
            message: error.response.data.message,
            type: "error",
          });
        }
      )
      .catch((e) => {setIsLoading(false); console.log(e)});
  };

  return (
    <section className="login-section-height">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="head-logo head-logo-for-user">
                <ImageTag src={HeadLogo} /> 
                
              </div>
              
              <div className="white-outer-box new-login-signup mt-5">
                <div className="signin-title">DEACTIVATE YOUR ACCOUNT</div>
                {responseObj.show ? (
                  <div
                    className={
                      responseObj.type == "success"
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {responseObj.message}
                  </div>
                ) : (
                  ""
                )}
                <div className="input-sign-in-inner">
                  <Form novalidate>
                    <Form.Group
                      className="mb-3 w-100"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="email"
                        name="email"
                        value={user.email}
                        onChange={onChangeHandler}
                        placeholder="Enter Email"
                        required
                      />
                      {formerrors.email && (
                        <p className="text-warning">{formerrors.email}</p>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-3 w-100"
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        type="password"
                        name="password"
                        value={user.password}
                        onChange={onChangeHandler}
                        placeholder="Enter Password"
                        required
                      />
                      {formerrors.password && (
                        <p className="text-warning">{formerrors.password}</p>
                      )}
                    </Form.Group>
                    
                    <Button
                      className="btn-primary w-100 mb-3"
                      variant="primary"
                      onClick={onSubmit}
                      type="button"
                      // disabled={!user.email || !user.password}
                    >
                      SUBMIT
                    </Button>
                    
                    
                  </Form>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        {isLoading?(
          <Loader />
        ):null}
      </section>
  );
};

export default DeActivateUser;
