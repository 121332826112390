import React from 'react'
// import HeadLogo from '../../assets/img/boost-logo.png'
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import EndPoint from "../../api/Endpoint";
import Constants from "../../api/Constants";
import { useNavigate, Link } from 'react-router-dom';
import Loader from '../Loading/Loader';
import HeadLogo from "../../assets/img/hom-login-large.png";
const UserForgotPassword = () => {
  
  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      window.location.href = `${Constants.FRONT_USER_LOGIN_URL}`;
    }
  }, []);
  
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [formerrors, setFormErrors] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const path = (window.location.origin+window.location.pathname).replace('forgot-password','')
  const [user, setUser] = useState({ email: "", current_url: path });
  const [responseObj, setResponce] = useState({
    show: false,
    message: "",
    type: "error",
  });
  const [isLoading, setIsLoading] = useState(false);
  const onChangeHandler = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };
  const validateInput = (event) => {
    let { name, value } = event.target;
    setFormErrors(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {

        case "email":
          if (!value) {
            stateObj[name] = "Email address is required.";
          } else if (!/\S+@\S+\.\S+/.test(user.email)) {
            stateObj["email"] = "Email address is invalid.";
          }
          // if (!user.email) {
          //   errors.email = "Email address is required";
          // } else if (!/\S+@\S+\.\S+/.test(user.email)) {
          //   errors.email = "Email address is invalid";
          // }
          break;

        default:
          break;
      }

      return stateObj;
    });
  }

  const validate = () => {
    let errors = {};

    //email field
    if (!user.email) {
      errors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(user.email)) {
      errors.email = "Email address is invalid";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };
  const onSubmit = (event) => {
    if (event) event.preventDefault();
    setIsLoading(true);
    console.log("########### onSubmit ###############", user )

    axios
      .post(EndPoint.FORGOT_PASSWORD_URL_FRONT, user)
      .then(
        (response) => {
          setResponce({
            show: true,
            message: "Reset password link has been sent to your registered email address.",
            type: "success",
          });
          setIsLoading(false);
          setUser({ email: "" });
          // window.location.href = `${EndPoint.FRONT_USER_LOGIN_URL}`;;
        },
        (error) => {
          setIsLoading(false);
          setResponce({
            show: true,
            message: error.response.data.message,
            type: "error",
          });
        }
      )
      .catch((e) => {setIsLoading(false);console.log(e)});
  };
  return (
    <section className='login-section-height'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='head-logo head-logo-for-user'>
              <img src={HeadLogo}></img>
            </div>
            {/* <div className='heade-title'>Welcome to Boost Mobile</div> */}
            <div className='white-outer-box new-login-signup'>
              <div className='signin-title'>FORGOT PASSWORD</div>
              <div className="input-sign-in-inner">
                {responseObj.show ? (
                  <div
                    className={
                      responseObj.type == "success"
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {responseObj.message}
                  </div>
                ) : (
                  ""
                )}
                <Form novalidate>
                  <Form.Group className="mb-3 w-100" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      name="email"
                      value={user.email}
                      onChange={onChangeHandler}
                      // onChange={validate}
                      onBlur={validateInput}
                      placeholder="Registered Email"
                    />
                    {formerrors.email && (
                      <p className="text-warning">{formerrors.email}</p>
                    )}
                  </Form.Group>
                  <Button className='btn-primary w-100 mb-3' variant="primary" onClick={onSubmit} type="button"
                    // disabled={formerrors.email || !user.email}
                  >
                    SUBMIT
                  </Button>
                  <div className='text-center dont-account'>Back to</div>
                  <div className='reset-text auto-sign-up w-100'>
                    <Link to={`${Constants.FRONT_BASE_PATH}/user/login`}>Sign In</Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading?(
        <Loader />
      ):null}
    </section>
  )
}

export default UserForgotPassword