import jwt_decode from "jwt-decode";

const CheckPermissionToPerformAction = () => {
    const checkPermission = () => {
        const currentUserToken = localStorage.getItem('token');
        if (!currentUserToken) {
            return { modulePriceCards: false, moduleDigitalFlyers: false };
        }

        const jwtData = jwt_decode(currentUserToken);
        const { role, settingsArray } = jwtData;

        let modulePriceCards = false;
        let moduleDigitalFlyers = false;

        if (role !== 'ADMIN') {
            const priceCardsModule = settingsArray.find(module => module.moduleSlug === 'price_cards');
            if (priceCardsModule) {
                modulePriceCards = priceCardsModule.showToUser;
            }

            const digitalFlyersModule = settingsArray.find(module => module.moduleSlug === 'digital_flyers');
            if (digitalFlyersModule) {
                moduleDigitalFlyers = digitalFlyersModule.showToUser;
            }
        } else {
            modulePriceCards = true;
            moduleDigitalFlyers = true;
        }

        return { modulePriceCards, moduleDigitalFlyers };
    };

    return checkPermission();
};

export default CheckPermissionToPerformAction;
