import { createSlice } from '@reduxjs/toolkit';

let token = JSON.parse(JSON.stringify(localStorage.getItem('token')));
let user = JSON.parse(JSON.stringify(localStorage.getItem('user')));
const userInfo = user ? user : {};
const initialAuthState = {
  isAuthenticated: (token)?true:false,
  userInfo:userInfo,
};
 
const authSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthState,
  reducers: {
    login(state,action) {console.log('login action',action);
    // debugger
      localStorage.setItem('token',  action.payload.data?.data.token);
      if(action.payload.data.data?.user){
        localStorage.setItem('user', JSON.stringify(action.payload.data.data?.user));
      }else{
        localStorage.setItem('user', JSON.stringify(action.payload.data.data));
      }
    
      state.isAuthenticated = true;
    },
    logout(state) {
      state.isAuthenticated = false;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('mfirstpopup');
     
    }
    
  },
});
 
export const authActions = authSlice.actions;
 
export default authSlice.reducer;