// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

var firebaseConfig = {
    apiKey: "AIzaSyAjD2Slfh0pMJKhQaWQqv7XGVJKKbtYsco",
    authDomain: "boostmerch-81f89.firebaseapp.com",
    projectId: "boostmerch-81f89",
    storageBucket: "boostmerch-81f89.appspot.com",
    messagingSenderId: "453625439401",
    appId: "1:453625439401:web:7e35c541c666d8037bb5b8",
    measurementId: "G-JNKHPZENYZ"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: `BJq_Kk6fU_Q9zw9G2AegwHD4O8I5ePWkkBHFEot822oshNb41EX5vb5RPcT3MRCsvyg_GcLVPSUmMGGzaEfyoRU` })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        localStorage.setItem('fpnt', currentToken); // Firebase Push Notification Token
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });



