import React from "react";
import Constants from "../../api/Constants";
import { useState, useEffect } from 'react';

const SignInAsAdmin = () => {

    // check user is admin or not for below link condition
    const user = JSON.parse(localStorage.getItem('user'));

    const [token, setToken] = useState('');

    useEffect(() => {
        // Assuming you have stored the token in local storage when it was generated
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
        setToken(storedToken);
        }
    }, []);

    const handleGoToAdminLink = async () => {
        try {
        // Redirect to the frontend application with the existing token
        // window.open(`${Constants.REACT_APP_FRONT_APPLICATION_BASE_URL}/admin/authenticate/${token}`, "_self");
        window.location.href= `${Constants.REACT_APP_FRONT_APPLICATION_BASE_URL}/admin/authenticate/${token}`;
        } catch (error) {
        console.error('Error redirecting:', error);
        }
    };

  return (
    <div className="d-flex justify-content-end mt-5 sign-in-link"> 
        {/* <a className="me-10" href="javascript:void(0)">Need Assistance?</a> */}
        {user && user.role === 'ADMIN' && (
            <a href="javascript:void(0)"  onClick={handleGoToAdminLink}>Administrator</a>
            // {`${Constants.REACT_APP_FRONT_APPLICATION_BASE_URL}`}
        )}
    </div> 
  );
};
export default SignInAsAdmin;
