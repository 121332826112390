import { combineReducers } from '@reduxjs/toolkit';
// import authReducer from '../../auth';
import authReducer from '../auth';
import settingReducer from '../setting';
 
const MainReducer = combineReducers(
    {  auth: authReducer ,
      setting:settingReducer
    
    }
);
 
const RootReducer = (state, action) => {
    if (action.type === "authentication/logout") {
      state = undefined
    }
    return MainReducer(state, action);
}
export default RootReducer;