import React from 'react'
// import HeadLogo from '../../assets/img/boost-logo.png'
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import EndPoint from "../../api/Endpoint";
import Constants from "../../api/Constants";
import { useNavigate, Link } from 'react-router-dom';
import Loader from '../Loading/Loader';

import HeadLogo from "../../assets/img/hom-login-large.png";
const checkValidity = (name, password, email,confirmPassword) => {
  return !!name && !!password && !!email && !!confirmPassword;
};
const UserSignUp = () => {

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      window.location.href = `${Constants.FRONT_BASE_URL}`;
    }
  }, []);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [formerrors, setFormErrors] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [user, setUser] = useState({ name:"", email: "", password: "" ,confirmPassword:""});
  const navigate = useNavigate();
  const [responseObj, setResponce] = useState({
    show: false,
    message: "",
    type: "error",
  });
  const [isLoading, setIsLoading] = useState(false);
  const path = (window.location.origin+window.location.pathname).replace('signup','')
  const isValid = checkValidity(user.name, user.password, user.email,user.confirmPassword);
  const onChangeHandler = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };


  const validateInput = (event) => {
    let { name, value } = event.target;
    setFormErrors(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "name":
          if (!value) {
            stateObj[name] = "Please enter name.";
          }
          break;
          case "email":
              if (!value) {
                stateObj[name] = "Email address is required";
            } else if (!/\S+@\S+\.\S+/.test(user.email)) {
              stateObj[name] = "Email address is invalid";
              }
            break;
  
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (user.confirmPassword && value !== user.confirmPassword) {
            stateObj["confirmPassword"] = "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = user.confirmPassword ? "" : formerrors.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (user.password && value !== user.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  }

  const validate = () => {
    let errors = {};
    //password field
    if (!user.password) {
      errors.password = "Password is required";
    }
    if (!user.name) {
      errors.name = "Name is required";
    }
    if(!user.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    }
    //email field
    if (!user.email) {
      errors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(user.email)) {
      errors.email = "Email address is invalid";
    }

    if (user.password) {
      if(user.password.length < 8){
        errors.password = "Minimum eight characters for password.";
      }
    }

    if(user.confirmPassword !== user.password){
      errors.confirmPassword = "Password and Confirm Password does not match.";
    }



    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };


  const onSubmit = (event) =>{
    if (event) event.preventDefault();
    if(!validate(user)){
      return;
    }
    if(!user.password.match(Constants.PASSWORD_VALIDATE)||!user.confirmPassword.match(Constants.PASSWORD_VALIDATE)){
      setResponce({
        show: true,
        message: "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
        type: "warning",
      });
      return;
    }
    const userSignUp = {
      name:user.name,
      password: user.password,
      email: user.email,
      current_url: path
    };
    setIsLoading(true);
    axios
      .post(EndPoint.SIGNUP_URL, userSignUp)
      .then(
        (response) => {
          setResponce({
            show: true,
            message: "SignUp Successfully",
            type: "success",
          });
          setIsLoading(false);
          // localStorage.setItem("token", response.data.data.token);
          // localStorage.setItem("user", JSON.stringify(response.data.data));
          setUser({ email: "", password: "" });
          navigate(`${Constants.FRONT_BASE_PATH}/user/verify-email`);
          // window.location.href = `${EndPoint.FRONT_VERIFY_EMAIL_URL}`;

        },
        (error) => {
          setIsLoading(false);
          setResponce({
            show: true,
            message: error.response.data.message,
            type: "error",
          });
        }
      )
      .catch((e) => console.log(e));
    
  };
  return (<>
 

    {/* // sign-up-work-start */}
    <section className='login-section-height'>
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='head-logo head-logo-for-user'>
            <img src={HeadLogo}></img>
          </div>
          {/* <div className='heade-title'>Welcome to Boost Mobile</div> */}
          <div className='white-outer-box new-login-signup'>
              <div className='signin-title'>SIGN UP</div>
                <div className="input-sign-in-inner">
                {responseObj.show ? (
                  <div
                    className={
                      responseObj.type == "success"
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {responseObj.message}
                  </div>
                ) : (
                  ""
                )}
                <Form novalidate>
                  <Form.Group className="mb-3 w-100" controlId="formBasicEmail">
                    <Form.Control
                      type="name"
                      name="name"
                      value={user.name}
                      onChange={onChangeHandler}
                      placeholder="Enter Name"
                      onBlur={validateInput}
                    />
                    {formerrors.name && (
                    <p className="text-warning">{formerrors.name}</p>
                  )}
                  </Form.Group>
                  <Form.Group className="mb-3 w-100" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      name="email"
                      value={user.email}
                      onChange={onChangeHandler}
                      placeholder="Email"
                      onBlur={validateInput}
                    />
                    {formerrors.email && (
                    <p className="text-warning">{formerrors.email}</p>
                  )}
                  </Form.Group>
                 
                  <Form.Group className=" mb-3 w-100" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      name="password"
                      value={user.password}
                      onChange={onChangeHandler}
                      placeholder="Enter Password"
                      onBlur={validateInput}
                    />
                     {formerrors.password && (
                      <p className="text-warning">{formerrors.password}</p>
                    )}
                  </Form.Group>
                   
                  <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      value={user.confirmPassword}
                      onChange={onChangeHandler}
                      placeholder="Enter confirm password"
                      onBlur={validateInput}
                    />
                     {formerrors.confirmPassword && (
                      <p className="text-warning">{formerrors.confirmPassword}</p>
                    )}
                  </Form.Group>
                  {/* <span className='reset-text d-flex justify-content-end'>
                  <a  href='/user/forgot-password'>Reset Password</a>
                </span> */}
                  <Button className='btn-primary w-100 mb-3' variant="primary" onClick={onSubmit} type="button"
                  // disabled ={!isValid ||(user.password !== user.confirmPassword)}
                  >
                   SIGN UP
                  </Button>
                  <div className='text-center dont-account'>Don't have an account?</div>
                <div className='reset-text auto-sign-up w-100'>
                  <Link to={`${Constants.FRONT_BASE_PATH}/user/login`}>Sign In</Link>
                </div>
                </Form>
              </div>
          </div>
        </div>
      </div>
    </div>
    {isLoading?(
      <Loader />
    ):null}
  </section>
  </>
  )
}

export default UserSignUp;