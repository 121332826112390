import { createSlice } from '@reduxjs/toolkit';

const initialSettingState = {
  isUnreadNotification:false,
};
 
const settingSlice = createSlice({
  name: 'setting',
  initialState: initialSettingState,
  reducers: {
    setUnreadNotificationFlag(state,action) {
      state.isUnreadNotification = action.payload;
    },
  },
});
 
export const settingActions = settingSlice.actions;
 
export default settingSlice.reducer;