import React from 'react'
import HeadLogo from '../../assets/img/boost-logo.png'
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import EndPoint from "../../api/Endpoint";
import Constants from "../../api/Constants";
import UserNavbar from "../../components/NavBar/userNav.jsx";
import jwt_decode from "jwt-decode";
import Loader from '../Loading/Loader';
import SignInAsAdmin from "../../components/SignInAsAdmin";

const checkValidity = ( currentPassword, password, confirmPassword) => {
  return  !!currentPassword && !!password &&  !!confirmPassword;
};
const UserChangePassword = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [formerrors, setFormErrors] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [responseObj, setResponce] = useState({
    show: false,
    message: "",
    type: "error",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      // window.location.href = `${Constants.FRONT_BASE_URL}`;
      fetchJsonWebToken();
    }
  }, []);

  const fetchJsonWebToken = async() =>{
    const tokenData = localStorage.getItem('token');
      // Decode the JWT token
      const jwtData = jwt_decode(tokenData);
      if(jwtData){
        setUserEmail(jwtData.email);
      }
  }

  const isValid = checkValidity(currentPassword , password,confirmPassword);
  // const onChangeHandler = (event) => {
  //   setUser({ ...user, [event.target.name]: event.target.value });
  // };


  const validateInput = (event) => {
    let { name, value } = event.target;
    setFormErrors(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        
        case "currentPassword":
          
            if (!value) {
                stateObj[name] = "Current Password is required.";
              }
              // else if(!user.currentPassword.match(passw)){
              //   stateObj[name] = "Minimum length requirement of 8 characters. ";
              // }
        
            break;
        case "password":
          if (!value) {
            stateObj[name] = "New password is required.";
          } else if (confirmPassword && value !== confirmPassword) {
            stateObj["confirmPassword"] = "New password and confirm new Password does not match.";
          } else {
            stateObj["confirmPassword"] = confirmPassword ? "" : formerrors.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Confirm new password is required.";
          } else if (password && value !== password) {
            stateObj[name] = "New password and confirm new Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  }

  const validate = () => {
    let errors = {};
    //password field
    if (!password) {
      errors.password = "New password is required.";
    }
    if(!confirmPassword) {
      errors.confirmPassword = "Confirm new password is required.";
    }

    if(!currentPassword) {
      errors.currentPassword = "Current Password is required.";
    }

    if((currentPassword && password) && currentPassword === password) {
      errors.password = "New password can't be equal to current password.";
    }

    if (currentPassword) {
      if(currentPassword.length < 8){
        errors.currentPassword = "Minimum eight characters for current password.";
      }
    }

    if (password) {
      if(password.length < 8){
        errors.password = "Minimum eight characters for password.";
      }
    }

    if(confirmPassword && confirmPassword !== password){
      errors.confirmPassword = "New password and confirm new password does not match.";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };



  const onSubmit = (event) =>{
    if (event) event.preventDefault();
    if(!validate()){
      console.log("########## validate() ##############", validate());
      return;
    }
    if(!currentPassword.match(Constants.PASSWORD_VALIDATE) ||!password.match(Constants.PASSWORD_VALIDATE)||!confirmPassword.match(Constants.PASSWORD_VALIDATE)){
      setResponce({
        show: true,
        message: "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
        type: "warning",
      });
      return;
    }
    const user = {
      password: currentPassword,
      newPassword: password,
      email: userEmail
    };
    const token =  localStorage.getItem('token');
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    setIsLoading(true);
    axios
      .post(EndPoint.Change_PASSWORD_URL_API, user, config)
      .then(
        (response) => {
          setResponce({
            show: true,
            message: "Password has been changed successfully.",
            type: "success",
          });
          setPassword("");
          setConfirmPassword("");
          setCurrentPassword("");
          setIsLoading(false);
          // setUser({ currentPassword: "",confirmPassword: "", password: "" });
          // window.location.href = `${EndPoint.FRONT_USER_LOGIN_URL}`;
        },
        (error) => {
          setIsLoading(false);
          setResponce({
            show: true,
            message: error.response.data.message,
            type: "error",
          });
        }
      )
      .catch((e) => console.log(e));
    
  };
  return (
    <>
    <UserNavbar />
    <div className="main-cover">
    <section className='login-section-height'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
          
            {/* <div className='head-logo'>
              <img src={HeadLogo}></img>
            </div>
            <div className='heade-title'>Welcome to Boost Mobile</div> */}
            <div className='white-outer-box new-login-signup'>
            {responseObj.show ? (
              <div
                className={
                  responseObj.type == "success"
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {responseObj.message}
              </div>
            ) : (
              ""
            )}
                  <div className="input-sign-in-inner">
                  <div className="signin-title">CHANGE PASSWORD</div>
                  <Form novalidate>
                  <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      name="currentPassword"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      // onChange={onChangeHandler}
                      placeholder="Current Password"
                      onBlur={validateInput}
                    />
                     {formerrors.currentPassword && (
                      <p className="text-warning">{formerrors.currentPassword}</p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      // onChange={onChangeHandler}
                      placeholder="New Password"
                      onBlur={validateInput}
                    />
                     {formerrors.password && (
                      <p className="text-warning">{formerrors.password}</p>
                    )}
                  </Form.Group>
                   
                  <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      value={confirmPassword}
                      // onChange={onChangeHandler}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm New Password"
                      onBlur={validateInput}
                    />
                     {formerrors.confirmPassword && (
                      <p className="text-warning">{formerrors.confirmPassword}</p>
                    )}
                  </Form.Group>
                  <Button className='btn-primary w-100 mb-3' variant="primary" onClick={onSubmit} type="button"
                  // disabled ={!isValid ||(password !== confirmPassword)}
                  >
                  SUBMIT
                  </Button>
                </Form>
                    
                  </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading?(
          <Loader />
      ):null}
    </section>
    <SignInAsAdmin></SignInAsAdmin></div>
    </>
  )
}
export default UserChangePassword;