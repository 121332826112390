import React from 'react';
import Modal from 'react-bootstrap/Modal'; // Import modal component library (e.g., Bootstrap modal)
import Button from 'react-bootstrap/Button'; // Import button component library (e.g., Bootstrap button)

const AlertPreviewModal = ({ show, handleClose, url, fileName, fileType }) => {
  return (
    <Modal show={show} onHide={handleClose} className='alert-preview-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Preview: {/* {fileName} */}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>
        {fileType === 'image' && (
          <img src={url} alt={fileName} style={{ maxWidth: '100%', maxHeight: '100%' }} />
        )}
        {fileType === 'pdf' && (
          <iframe src={url} title={fileName} width="100%" height="600px" />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertPreviewModal;