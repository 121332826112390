const Constants = {
    BASE_URL:process.env.REACT_APP_BASE_URL,
    FRONT_BASE_URL:process.env.REACT_APP_FRONT_BASE_URL,
    REACT_APP_FRONT_APPLICATION_BASE_URL:process.env.REACT_APP_FRONT_APPLICATION_BASE_URL,
    FRONT_BASE_PATH:'',
    IMAGE_URL:'',
    PAGINATION:10,
    FLYER_PAGINATION:100,
    PDF_FILE_SIZE_LIMIT:process.env.REACT_APP_PDF_FILE_SIZE_LIMIT,
    GOOGLE_ANALYTICS_API_KEY:process.env.REACT_APP_GOOGLE_ANALYTICS_API_KEY,
    GOOGLE_ANALYTICS_AUTH_DOMAIN:process.env.REACT_APP_GOOGLE_ANALYTICS_AUTH_DOMAIN,
    GOOGLE_ANALYTICS_PROJECT_ID:process.env.REACT_APP_GOOGLE_ANALYTICS_PROJECT_ID,
    GOOGLE_ANALYTICS_STORAGE_BUCKET:process.env.REACT_APP_GOOGLE_ANALYTICS_STORAGE_BUCKET,
    GOOGLE_ANALYTICS_MESSAGING_SENDER_ID:process.env.REACT_APP_GOOGLE_ANALYTICS_MESSAGING_SENDER_ID,
    GOOGLE_ANALYTICS_APP_ID:process.env.REACT_APP_GOOGLE_ANALYTICS_APP_ID,
    GOOGLE_ANALYTICS_MEASUREMENT_ID:process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID
}
export default Constants;