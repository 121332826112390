import React, { useEffect, useState } from 'react'
// import HeadLogo from '../../assets/img/boost-logo.png'
import ImageTag from "../ImageTag";
import axios from "axios";
import EndPoint from "../../api/Endpoint";
import { useSearchParams, useNavigate } from 'react-router-dom';
import Loader from '../Loading/Loader';
import {Link} from 'react-router-dom';
import Constants from "../../api/Constants";

import HeadLogo from "../../assets/img/hom-login-large.png";
export default function 
UserReset() {
  const [searchParams, setSearchParams] = useSearchParams();
  let token = searchParams.get('token');
  const [formData, setFormData] = useState({ token: token});
  const [formerrors, setFormErrors] = useState({});
  const [showMessage, setShowMessage] = useState("Loading please wait..............");
  const [responseObj, setResponce] = useState({
    show: false,
    message: "",
    type: "error",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(()=>{
    onVerifyEmail();
  },[])
  const onVerifyEmail = () => {
    if(!token){
      return;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+token
    }
    setIsLoading(true);
    axios
      .post(EndPoint.VERIFY_EMAIL_API, formData,{
        headers:headers
      })
      .then(
        (response) => {
          setIsLoading(false);
          console.log("############# Response data ##################", response);
          setResponce({
            show: true,
            message: "Email verified Successfully",
            type: "success",
          });
          // navigate('/user/login')
          // window.location.href = `${EndPoint.FRONT_LOGIN_URL}`;
        },
        (error) => {
          setIsLoading(false);
          setResponce({
            show: true,
            message: error.response.data.message,
            type: "error",
          });
        }
      )
      .catch((e) => {setIsLoading(false);console.log(e)});
  };

  return (
    <>
    <section className='login-section-height'>
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='head-logo head-logo-for-user'>
            <img src={HeadLogo}></img>
          </div>
          {/* <div className='heade-title'>Welcome to Boost Mobile</div> */}
          <div className='white-outer-box new-login-signup'>
              <div className='signin-title'>VERIFY YOUR EMAIL</div>
                <div className="input-sign-in-inner">
                  {
                    !token ?
                    <div className='text-center dont-account'>A verification link sent to your registered email address. Kindly verify your account by clicking on the link.</div>
                    :
                    <div className='text-center dont-account'>
                      {responseObj.show ? 
                      responseObj.type == "error"?
                      (
                        <div
                          className={
                            responseObj.type == "success"
                              ? "alert alert-success"
                              : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {responseObj.message ?
                            responseObj.message
                            :""
                          }
                        </div>
                      ) : (
                        <div
                          className={
                            responseObj.type == "success"
                              ? "alert alert-success"
                              : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {responseObj.message}
                        </div>
                      ):("")}
                    </div>
                  }
                </div>
                {responseObj.show ? (
                  <div className="reset-text auto-sign-up w-100">
                    <Link to={`${Constants.FRONT_BASE_PATH}/user/login`}>Sign In</Link>
                  </div>)
                  :null
                }
          </div>
        </div>
      </div>
    </div>
    {isLoading?(
        <Loader />
      ):null}
    </section>
    </>
  )
}