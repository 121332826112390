import { useEffect, useState } from "react";
import Navbar from "../../components/NavBar/userNav";
import Loader from "../../components/Loading/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignInAsAdmin from "../SignInAsAdmin";
import { formatDate } from "../../components/_services/common.services";
import listPdfIcon from "../../assets/img/alert-list-pdf.svg";
import listImageIcon from "../../assets/img/alert-list-image.svg";
import { useHttpClient } from "../../hooks/HttpHook";
import EndPoint from "../../api/Endpoint";
import { settingActions } from "../../store/setting";
import { useDispatch } from "react-redux";
import Constants from "../../api/Constants";
import AlertPreviewModal from "./alert-preview-modal";

const AlertsIndex = () => {

    const [isLoading, setIsLoading] = useState(false);
    const { sendRequest } = useHttpClient();
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
    const [modulesList, setModulesList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [mAnyNotificationUnread, setMAnyNotificationUnread] = useState(1);
    const dispatch=useDispatch()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [previewFileName, setPreviewFileName] = useState('');
    const [previewFileType, setPreviewFileType] = useState('');

    const fetchNotificationStatus = async () => {
        try {
            let deviceId = JSON.parse(localStorage.getItem('user')).userId;
            if(deviceId == '' || deviceId == undefined) {
                deviceId = JSON.parse(localStorage.getItem('user'))._id;
            }
            const responseData = await sendRequest(
                EndPoint.GET_IF_ANY_UNREAD_ALERT+`${deviceId}`,
                "GET",
            );

            // Update state based on API response

            dispatch(settingActions.setUnreadNotificationFlag(responseData.isAnyNotificationUnread));
            setMAnyNotificationUnread(responseData.isAnyNotificationUnread);
        } catch (error) {
            console.error('Error fetching notification status:', error);
        }
    };


    const fetchData = async (pageArg) => {
        let deviceId = JSON.parse(localStorage.getItem('user')).userId;
        if(deviceId == '' || deviceId == undefined) {
            deviceId = JSON.parse(localStorage.getItem('user'))._id;
        }

        let body ={
            page: pageArg,
            deviceId: deviceId,
        };

        // api Call
        setIsLoading(true);
        try {
            const responseData = await sendRequest(
                EndPoint.ALERT_LIST_FOR_WEBFRONT_AND_MOBILE,
                "POST",
                body
            );

            setIsLoading(false);
            // const lengthData = responseData.allAlerts.length;
            const lengthData = pageArg * Constants.PAGINATION;
            const totalCountVar = responseData.totalNumOfAlerts;

            if(lengthData >= totalCountVar) {
                setShowLoadMoreButton(false);
            } else {
                setShowLoadMoreButton(true);
            }
            const initialDataArr = responseData.allAlerts; 
            // setModulesList(initialDataArr);
            setModulesList((prevList) => [...prevList, ...initialDataArr]);
            fetchNotificationStatus()

        
        } catch (err) {
            setIsLoading(false);
        }
    };

    const handleLoadMore = () =>{
        const pageCount = pageNumber;
        setPageNumber(pageCount+1);
        fetchData(pageCount+1);
    }

    const handleFileDownloadIndex = async(event, id, attachment) => {
        try {
            setIsLoading(true);
            const responseData = await sendRequest(
                EndPoint.GET_ALERT_FILE+`${attachment}`,
                "GET",
            );

            setIsLoading(false);
        
            if (!responseData.error) {
                /* console.log(responseData.data);
                const url = responseData.data;
                const fileName = responseData.name;
        
                // Create a temporary link element
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName; // Set the file name for the download
        
                // Append the link element to the document body
                document.body.appendChild(a);
                a.target = '_blank';
                // Trigger a click event on the link to initiate the download
                a.click();
        
                // Remove the link element from the document body
                document.body.removeChild(a);
        
                // Revoke the Blob URL to free up memory
                URL.revokeObjectURL(url); */

                // Extract data from response
                const url = responseData.data;
                const fileName = responseData.name;

                // Determine file type based on file name
                const fileType = getFileType(fileName);

                // Set preview data to show in modal
                setPreviewUrl(url);
                setPreviewFileName(fileName);
                setPreviewFileType(fileType);

                // Open the modal to display the file preview
                setIsModalOpen(true);
            }
        } catch (err) {
            setIsLoading(false);
            toast.error('File not found on bucket', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.log(err);
        }
    }

    const getFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        if (extension === 'pdf') {
          return 'pdf';
        } else {
          return 'image';
        }
      };
    
      const handleCloseModal = () => {
        // Close the modal and reset preview data
        setIsModalOpen(false);
        setPreviewUrl('');
        setPreviewFileName('');
        setPreviewFileType('');
      };

    const handleReadNotification = async(event, id) => {console.log(event);
        try {

            let deviceId = JSON.parse(localStorage.getItem('user')).userId;
            if(deviceId == '' || deviceId == undefined) {
                deviceId = JSON.parse(localStorage.getItem('user'))._id;
            }

            let body = {
                alertId: id,
                deviceId: deviceId,
            };
            
            setIsLoading(true);
            const responseData = await sendRequest(
                EndPoint.MARK_ALERT_AS_READ,
                "POST",
                body
            );

            if (!responseData.error) {
                // await fetchData(pageNumber);
                // Find the element with class "unread<elementId>"
                const elementm = document.querySelector(`.mUnRead${id}`);

                // If the element is found, remove the "unread" class
                if (elementm) {
                    elementm.classList.remove('mUnRead');
                }

                fetchNotificationStatus();

                setIsLoading(false);
                /* toast.success('Notification read successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }); */
            }
        } catch (err) {
            setIsLoading(false);
            // toast.error('Error to mark as read this notification', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            // });
            console.log(err);
        }
    }

    const handleReadAllNotification = async() => {
        try {

            let deviceId = JSON.parse(localStorage.getItem('user')).userId;
            if(deviceId == '' || deviceId == undefined) {
                deviceId = JSON.parse(localStorage.getItem('user'))._id;
            }

            let body = {
                deviceId: deviceId,
            };
            
            setIsLoading(true);
            const responseData = await sendRequest(
                EndPoint.MARK_ALL_ALERT_AS_READ,
                "POST",
                body
            );

            if (!responseData.error) {
                // await fetchData(pageNumber);
                
                // Select all elements with class "mUnRead"
                const elements = document.querySelectorAll('.mUnRead');

                // Iterate over each matched element
                elements.forEach(element => {
                    // Remove the "mUnRead" class from the current element
                    element.classList.remove('mUnRead');
                });

                fetchNotificationStatus();

                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            // toast.error('Error to mark as read this notification', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            // });
            console.log(err);
        }
    }
  
    useEffect(() => {
        fetchData(1);
    }, []);

    return (
        <>
        <Navbar />
        <ToastContainer/>
        <div className="main-cover">
            <div className="container">
                <div className="row mt-4 mb-4">
                    <div className="col-md-6 category-title">Merch Alerts</div>
                    <div className="col-md-6 text-right">
                    {mAnyNotificationUnread && (
                        <button className="btn btn-primary no-capital" onClick={handleReadAllNotification}>
                        Mark all as read
                        </button>
                    )}
                    </div>
                </div>

                {
                modulesList.length > 0 ?
                modulesList.map((element,index)=>{
                    return(
                        <div className="border-cover"> 
                            <div className={`alert-box gap-4 justify-content-between ${!element.isRead ? 'mUnRead' : ''} mUnRead${element._id}`} onClick={!element.isRead ? () => handleReadNotification(element, element._id) : null}>
                                <div className="row">
                                    <div className="col-md-9 col-lg-10">
                                        <div className="messageDiv">{element.message}</div>
                                        <div className="mt-3 fst-italic">
                                            {formatDate(element.createdAt, 'MDT')}
                                        </div>
                                    </div>
                                    
                                    {element.attachmentType === '' ? (
                                        ''
                                    ) : element.attachmentType === 'application/pdf' ? (
                                        <div className="col-md-3 col-lg-2 text-md-right text-center">
                                            <div className="notification-right-img"><img onClick={(event)=>handleFileDownloadIndex(event, element._id, element.attachment)} className="mListIcon" src={listPdfIcon} alt="PDF Icon" />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-md-3 col-lg-2 text-md-right text-center">
                                            <div className="notification-right-img">
                                                <img onClick={(event)=>handleFileDownloadIndex(event, element._id, element.attachment)} className="mListIcon" src={element.filePreviewUrl} /* {listImageIcon} */ alt="Image Icon" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })
                    : '' //isLoading ? "Loading records..." : "No record found."
                }

                {
                    showLoadMoreButton &&
                    <div className="text-center load-btn">
                        <button onClick={handleLoadMore} type="button" className="btn ">
                            Load More...
                        </button>
                    </div>
                }

                {isLoading ? (<Loader />) : null}
            </div>

            <AlertPreviewModal
                show={isModalOpen}
                handleClose={handleCloseModal}
                url={previewUrl}
                fileName={previewFileName}
                fileType={previewFileType}
            />

            <SignInAsAdmin></SignInAsAdmin>
        </div>
        </>
    );
};

export default AlertsIndex;
