import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../../components/_services/common.services";
import listPdfIcon from "../../assets/img/alert-list-pdf.svg";
import listImageIcon from "../../assets/img/alert-list-image.svg";
import Constants from "../../api/Constants";

const AlertViewModal = (prop) => {
    return (
        <>
            <Modal className="view-alert-modal" show={prop.showModal} onHide={prop.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Merch Alerts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {prop.viewData.length === 0 ? (
                        <div className="text-center">No new alerts</div>
                    ) : (
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Message</th>
                                        <th>Attachment</th>
                                        <th>Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {prop.viewData.slice(0, 5).map(alert => (
                                        <tr key={alert._id}>
                                            <td>{alert.message.length > 100 ? `${alert.message.substring(0, 100)} .....` : alert.message}</td>
                                            <td>
                                                {alert.attachmentType === '' ? (
                                                    'N/A'
                                                ) : alert.attachmentType === 'application/pdf' ? (
                                                    <img onClick={(event) => prop.handleFileDownload(event, alert._id, alert.attachment)} className="mListIconViewModal" src={listPdfIcon} alt="PDF Icon" />
                                                ) : (
                                                    <img onClick={(event) => prop.handleFileDownload(event, alert._id, alert.attachment)} className="mListIconViewModal" src={alert.filePreviewUrl} /* {listImageIcon} */ alt="Image Icon" />
                                                )}
                                            </td>
                                            <td className="fst-italic-view">{formatDate(alert.createdAt, 'MDT')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {prop.unreadAlertsCount > 5 && (
                                <div className="text-left fw-bold">
                                    <a href={`${Constants.FRONT_BASE_PATH}/user/alerts`}>+ {prop.unreadAlertsCount - 5} more unread messages</a>
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="border-top">
                    <div className="text-right">
                        <a className="mViewAllLink btn btn-primary" href={`${Constants.FRONT_BASE_PATH}/user/alerts`}>View All</a>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AlertViewModal;
